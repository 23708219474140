.btn-blank {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
.page-title {
  color: $primary-color;
  margin: 20px 0;
}

tbody tr:nth-child(odd) {
  background-color: $light-grey;
}

tr {
  background-color: #fff;
}

td {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  margin: 20px;
}

td:first-child {
  padding-left: 20px;
  padding-right: 0;
}

.white-card {
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background-color: #fff;
}
.card-title {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  color: black;
}
.horizontal-line {
  width: "100%";
  height: 2px;
  background-color: $divider-color;
  margin: 10px 0;
}

.f-light {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}
.f-regular {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.f-medium {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}
.f-semibold {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.f-bold {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}
.f-extrabold {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

.clr-black {
  color: #000;
}

.card-close-icon {
  position: absolute;
  right: 0;
  top: 0;
}
.card-content-container {
  width: 100%;
}

.view-details-btn {
  position: relative;
}

.view-details-btn .tooltiptext {
  font-size: 12px;
  visibility: hidden;
  width: 120px;
  // background-color: black;
  color: $primary-color;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  top: -25px;
  left: -40px;
  z-index: 1;
}

.view-details-btn:hover .tooltiptext {
  visibility: visible;
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.w-100 {
  width: 100%;
}
.bg-red {
  background-color: $danger-red !important;
  border: 1px solid $danger-red;
}
.date-picker-container {
  position: relative;
  z-index: 10;
}
.zindex-1 {
  z-index: 1;
}
.zindex-2 {
  z-index: 2;
}
.zindex-3 {
  z-index: 10;
}

.date-picker-label {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.app-date-picker-container {
  width: 100%;
  border: 1px solid #e0dbdb;
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 14px;
  font-style: italic;
  background-color: #ffffff !important;
  margin: 8px 0;
}
.app-date-picker-container:focus {
  outline: none;
  font-weight: bold;
  font-style: normal;
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 4pt 0.5pt rgba(213, 201, 201, 0.75);
  -moz-box-shadow: 0 0 4pt 0.5pt rgba(213, 201, 201, 0.75);
  box-shadow: 0 0 4pt 0.5pt rgba(213, 201, 201, 0.75);
}

.box-h-35vh {
  height: 25vh !important;
}
.h-65 {
  height: 55vh !important;
}

.report-ans {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  padding: 8px;
  width: 25px;
  height: 25px;
}

.report-ans-green {
  background-color: #359e35;
}

.report-ans-red {
  background-color: rgb(227, 33, 56);
}

.position-bottom-20b-10r {
  position: absolute;
  bottom: 20px;
  right: 10px;
}
.items-center {
  align-items: center;
}

.graph-patient-name {
  font-size: 14px;
  color: #797979;
}
