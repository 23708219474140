.input-form {
  max-width: 500px;
  min-width: 300px;
  .app-input {
    width: 100%;
    border: 1px solid #e0dbdb;
    padding: 10px 15px;
    border-radius: 8px;
    font-size: 14px;
    font-style: italic;
    background-color: #ffffff !important;
    margin: 8px 0;
  }
  .app-input:focus {
    outline: none;
    font-weight: bold;
    font-style: normal;
    background-color: #fff !important;
    -webkit-box-shadow: 0 0 4pt 0.5pt rgba(213, 201, 201, 0.75);
    -moz-box-shadow: 0 0 4pt 0.5pt rgba(213, 201, 201, 0.75);
    box-shadow: 0 0 4pt 0.5pt rgba(213, 201, 201, 0.75);
  }
  .btn-container {
    padding: 10px 0px;
  }
}

.dropdown-container {
  max-width: 500px !important;
  min-width: 240px !important;
}

.input-dropdown {
  width: 100%;
  border: 1px solid #e0dbdb;
  padding: 3px 5px;
  border-radius: 8px;
  font-size: 14px;
  font-style: italic;
  background-color: #ffffff !important;
  margin: 8px 0;
}

.btn-small-primary {
  font-size: 13px;
  background-color: $primary-color;
  color: #fff;
  padding: 5px 30px;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
}
.btn-small-outline {
  font-size: 13px;
  border-color: $primary-color;
  background-color: #fff;
  color: #000;
  padding: 8px 30px;
  border-radius: 5px;
  border-width: 1px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
}
.add-survey-form {
  max-width: auto !important;
  min-width: auto !important;
}
.edit-surgeon-form {
  max-width: 250px;
}
.app-date-input {
  width: 100%;
  border: 1px solid #e0dbdb;
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 14px;
  font-style: italic;
  background-color: #ffffff !important;
  margin: 20px 0 !important;
  outline: none !important;

  :focus {
    outline: none !important;
    font-weight: bold;
    font-style: normal;
    background-color: #fff !important;
    -webkit-box-shadow: 0 0 4pt 0.5pt rgba(213, 201, 201, 0.75);
    -moz-box-shadow: 0 0 4pt 0.5pt rgba(213, 201, 201, 0.75);
    box-shadow: 0 0 4pt 0.5pt rgba(213, 201, 201, 0.75);
  }
}

.app-date-input .Mui-focused .MuiInputBase-input {
  outline: none !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.dob-picker-container {
  width: 100%;
}

.form-error {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #e74545;
  margin-top: -5px;
}

.password-input {
  outline: none;
  border: none;
  background-color: #f8f8f8;
  font-size: 14px;
  font-style: italic;
}
.password-input:focus {
  outline: none;
  font-weight: bold;
  font-style: normal;
  background-color: #fff !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.password-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
  border-radius: 8px;
  background-color: #f8f8f8 !important;
  margin: 8px 0;
  padding-right: 10px;
}

.pic-focused {
  outline: none;
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 4pt 0.5pt rgba(213, 201, 201, 0.75);
  -moz-box-shadow: 0 0 4pt 0.5pt rgba(213, 201, 201, 0.75);
  box-shadow: 0 0 4pt 0.5pt rgba(213, 201, 201, 0.75);
}

.show-password-btn {
  padding: 5px 10px;
  font-weight: 700;
  font-size: 14px;
}

.app-datepicker-input {
  width: 100%;
  border: 1px solid #e0dbdb;
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 14px;
  font-style: italic;
  background-color: #ffffff !important;
  margin: 8px 0;
  .Mui-focused {
    outline: none !important;
    border: none !important;
    font-weight: bold;
    font-style: normal;
    background-color: #fff !important;
    -webkit-box-shadow: 0 0 4pt 0.5pt rgba(213, 201, 201, 0.75);
    -moz-box-shadow: 0 0 4pt 0.5pt rgba(213, 201, 201, 0.75);
    box-shadow: 0 0 4pt 0.5pt rgba(213, 201, 201, 0.75);
  }
}

#app-datepicker-inputid {
  padding: 10px 15px !important;
  border-radius: 15px !important;
}

.app-datepicker-input:focus {
  outline: none;
  font-weight: bold;
  font-style: normal;
  background-color: #731212 !important;
  -webkit-box-shadow: 0 0 4pt 0.5pt rgba(213, 201, 201, 0.75);
  -moz-box-shadow: 0 0 4pt 0.5pt rgba(213, 201, 201, 0.75);
  box-shadow: 0 0 4pt 0.5pt rgba(213, 201, 201, 0.75);
}

.MuiDateInput-root {
  /* Customize the root styles of the DatePicker */
  display: block; /* Example: Change the display to block */
  margin: 10px; /* Example: Add margin */
  // background-color: #007bff;
}

.app-datepicker-container {
  width: 100%;
}
