.sidebar-container {
  height: 100vh;
  z-index: 1 !important;
}
.pro-sidebar-inner {
  background-color: #ffffff !important;
}

.sidebar-collapse-icon-container {
  position: absolute;
  top: 10px;
  right: 10px;
}

.sidebar-header-container {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  .brand-detail-container {
    padding-top: 20px;
  }
}

.sidebar-brand-title {
  margin-top: 20px;
  color: #000;
  font-family: "Montserrat", sans-serif;
}

.sidebar-menu-label {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}
.sidebar-menu-label-active {
  font-weight: 600;
}
.sidebar-footer-label {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #000;
}

.logout-icon-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 3px;

  .logout-icon {
    padding-bottom: 10px;
  }
}

.prof-card-container {
  background-color: #4141cd;
  margin: 8px;
  border-radius: 8px;
  padding: 10px 8px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .detail-col {
    width: 90%;
  }
  .icon-col {
    width: 10%;
  }
  .full-name {
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: #f9f9f9;
  }
  .email {
    padding-right: 10px;
    white-space: nowrap;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #c6c2c2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .surgeon-id {
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #c6c2c2;
    letter-spacing: 1.5px;
  }
}

.profile-icon-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
